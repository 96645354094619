import { ActionLink, HorizontalStack, SectionHeader } from "@/lib";
import * as React from "react";
import { mdiArrowLeft } from "@mdi/js";
import RecommendationMeasureGroup from "./MeasureGroup";
import { t } from "@/utils";
import { useProject } from "@/context";
import { useParams, useLocation } from "react-router-dom";
import CryptoJS from "crypto-js";

interface ISingleAction {
    site: INewSiteDetailed;
}

export default function SingleAction(props: ISingleAction): JSX.Element {
    const project = useProject();
    const report = project.reports[project.latest_report_id] as IAssessmentReport;
    const location = useLocation();
    const { data, key } = location.state || {};

    const [singleActionPlan, setSingleActionPlan] = React.useState([]);
    React.useEffect(() => {
        if (data) {
            try {
                const uniqueRecommendations = Array.from(new Map(data.map((item) => [item.action.recommendation_id, item])).values());
                console.log("all action: ", data);
                console.log("all action unique: ", uniqueRecommendations);

                setSingleActionPlan(uniqueRecommendations);
            } catch (error) {
                console.error("Error decoding or parsing the data:", error);
            }
        }
    }, [data]);

    return (
        <div>
            <HorizontalStack style={{ marginBottom: "1rem", marginTop: "1rem" }}>
                <ActionLink onClick={() => history.back()} icon={mdiArrowLeft} iconColor="#4973B6">
                    {t("actions.back")}
                </ActionLink>
            </HorizontalStack>

            {singleActionPlan.length > 0 && (
                <RecommendationMeasureGroup
                    key={singleActionPlan[0].action.recommendation_id}
                    label={singleActionPlan[0].action.cluster_name}
                    actionPlan={singleActionPlan}
                    project={project}
                    report={report}
                    site={props.site}
                />
            )}
            {/* <div>
                <SectionHeader level={5} text={t('ipbes.action_drivers_title')} bordered={false} />
                { singleActionPlan.length > 0 && singleActionPlan[0].action.ipbes.map((item: any, index: number) => {
                    return (
                        <p key={index}>{item?.name}</p>
                    );
                })}
            </div> */}
            <div>
                <SectionHeader level={5} text={t("ipbes.action_drivers_title")} bordered={false} />
                {singleActionPlan.length > 0 && <p key={singleActionPlan[0].action.ipbes.code}>{singleActionPlan[0].action.ipbes.name}</p>}
            </div>
        </div>
    );
}
