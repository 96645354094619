import React from 'react';
import { colors } from '@/utils/style';

interface HeaderKpiProps {
    label: string;
    value: string | number;
}

const HeaderKpi: React.FC<HeaderKpiProps> = ({ label, value }) => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0 10px',
            minWidth: '150px'
        }}>
            <div style={{
                fontSize: '14px',
                color: colors.gray,
                textAlign: 'center',
                marginBottom: '5px'
            }}>
                {label}
            </div>
            <div style={{
                fontSize: '24px',
                fontWeight: 'bold',
                color: colors.white
            }}>
                {value}
            </div>
        </div>
    );
};

export default HeaderKpi;
