import { Rating } from "@/types/utils.d";

export const pickRatingColor = (rating: Rating) => {
    if (rating === Rating.VERY_HIGH) {
        return "#e23d4a";
    } else if (rating === Rating.HIGH) {
        return "#A04000";
    } else if (rating === Rating.MEDIUM) {
        return "#B7950B";
    } else {
        return "#196F3D";
    }
};

export const getBubbleBarColor = (sites: ImpactDependencyValues[] | iCluster[]) => {
    console.log(" res length: ", sites.length);
    if (sites.length === 0) {
        return pickRatingColor(Rating.LOW);
    } else {
        for (const site of sites) {
            if (site.rating === Rating.VERY_HIGH) {
                return pickRatingColor(Rating.VERY_HIGH);
            }
            if (site.rating === Rating.HIGH) {
                return pickRatingColor(Rating.HIGH);
            }
            if (site.rating === Rating.MEDIUM) {
                return pickRatingColor(Rating.MEDIUM);
            } else {
                return pickRatingColor(Rating.LOW);
            }
        }
    }
};
