/* eslint-disable @typescript-eslint/no-explicit-any */
import CryptoJS from "crypto-js";
if (!process.env.API_KEY || !process.env.SECRET_KEY || !process.env.KUYUA_APP_BACKEND_URL || !process.env.GEO_SERVER_URL) {
    throw new Error("Missing necessary environment variables.");
}
const encryptedToken = CryptoJS.AES.encrypt(process.env.API_KEY, process.env.SECRET_KEY).toString();
const header = {
    headers: {
        Authorization: `Bearer ${encryptedToken}`,
    },
};

export const sbm3Table = async (site) => {
    if (!site) {
        throw new Error("Missing site information.");
    }
    try {
        const response = await fetch(`${process.env.KUYUA_APP_BACKEND_URL}/sbm3table/${site.site_id}`, header);
        const data = await response.json();
        return data.finalData;
    } catch (error) {
        console.error("Error fetching sbm3 table data:", error);
        throw error;
    }
};

export const sbm3TableForTeam = async (team) => {
    if (!team) {
        throw new Error("Missing team information.");
    }
    try {
        const response = await fetch(`${process.env.KUYUA_APP_BACKEND_URL}/sbm3table/company/${team}`, header);
        const data = await response.json();
        return data.finalData;
    } catch (error) {
        console.error("Error fetching sbm3 table for company data:", error);
        throw error;
    }
};
