import React, { useContext } from "react";
import { AppContext, NavigationChanged, ShowSideStepDetail, SitesChanged, useProject, useTeam } from "@/context";
import { fetchDivisions, fetchEconomicActivities, fetchSections } from "@/rest/apiHelper";
import { apiGet } from "@/utils/http";
import { IOrderParam } from "@/types/site";
import { iActivities, INewDBSite, CompanyEconomicActivity, ActivityState, ActivitySelected } from "@/types/sleap";
import CryptoJS from "crypto-js";
const encryptedToken = CryptoJS.AES.encrypt(process.env.API_KEY, process.env.SECRET_KEY).toString();
const header = {
    headers: {
        Authorization: `Bearer ${encryptedToken}`,
    },
};

type SleapStepHook = [string, (newStep: string) => void, () => void];

type ActivitiesHook = [ActivityState, (selected: number[], type: "industry" | "sub_industry" | "economic_activity") => void, string[]];

export function useSleapStep(): SleapStepHook {
    const { state, dispatch } = React.useContext(AppContext);

    const [step, setStep] = React.useState("");

    React.useEffect(() => {
        step !== state.sideStepActive && setStep(state.sideStepActive);
    }, [state.sideStepActive]);

    const setStepHandler = React.useCallback((newStep: string) => {
        dispatch({ type: NavigationChanged, sideStepActive: newStep });
    }, []);

    const toggleStepDetail = React.useCallback(() => {
        dispatch({ type: ShowSideStepDetail, value: !state.showSideStepDetail });
    }, []);

    return [step, setStepHandler, toggleStepDetail];
}

export function useActivities(site: INewDBSite = null, companyEconomicActivities?: CompanyEconomicActivity[]): ActivitiesHook {
    console.log("🚀 ~ companyEconomicActivities:", companyEconomicActivities);
    const team = useTeam();
    const [activities, setActivities] = React.useState<ActivityState>({
        companyActivities: [],
        selected: {
            industry_ids: [],
            sub_industry_ids: [],
            economic_activity_ids: [],
        },
        options: {
            industries: [],
            sub_industries: [],
            economic_activities: [],
        },
    });

    React.useEffect(() => {
        if (site) {
            setActivities((prev) => ({
                ...prev,
                selected: {
                    economic_activity_ids: site.economic_activities.map((activity) => activity.economic_activity_id),
                    industry_ids: site.economic_activities.map((activity) => activity.industry_id),
                    sub_industry_ids: site.economic_activities.map((activity) => activity.subindustry_id),
                },
            }));
        }
    }, [site]);

    async function loadSectionOptions() {
        try {
            const response = await fetch(`${process.env.KUYUA_APP_BACKEND_URL}/industries`, header);
            const data = await response.json();
            setActivities((prev) => ({
                ...prev,
                options: {
                    ...prev.options,
                    industries: data?.industries || [],
                },
            }));
        } catch (e) {
            console.error("Error fetching sections:", e);
        }
    }

    async function loadDivisions() {
        try {
            const response = await fetch(`${process.env.KUYUA_APP_BACKEND_URL}/sub-industries`, header);
            const data = await response.json();
            setActivities((prev) => ({
                ...prev,
                options: {
                    ...prev.options,
                    sub_industries: data?.sub_industries || [],
                },
            }));
        } catch (e) {
            console.error("Error fetching divisions:", e);
        }
    }

    async function loadEconomicActivities() {
        try {
            const response = await fetch(`${process.env.KUYUA_APP_BACKEND_URL}/economic-activities/activities`, header);
            const data = await response.json();
            setActivities((prev) => ({
                ...prev,
                options: {
                    ...prev.options,
                    economic_activities: data?.economic_activities || [],
                },
            }));
        } catch (e) {
            console.error("Error fetching economic activities:", e);
        }
    }

    React.useEffect(() => {
        Promise.all([loadSectionOptions(), loadDivisions(), loadEconomicActivities()]);
    }, []);

    React.useEffect(() => {
        if (companyEconomicActivities && companyEconomicActivities.length > 0) {
            setActivities((prev) => ({
                ...prev,
                companyActivities: companyEconomicActivities,
                selected: {
                    industry_ids: [...new Set(companyEconomicActivities.map((act) => act.industry_id))],
                    sub_industry_ids: [...new Set(companyEconomicActivities.map((act) => act.sub_industry_id))],
                    economic_activity_ids: companyEconomicActivities.map((act) => act.economic_activity_id),
                },
            }));
        }
    }, [companyEconomicActivities]);

    const handleSelect = React.useCallback((selected: number[], type: "industry" | "sub_industry" | "economic_activity") => {
        const key = `${type}_ids` as keyof ActivitySelected;
        setActivities((prev) => {
            let newSelected = { ...prev.selected };

            // When changing industries
            if (type === "industry") {
                const removedIndustries = prev.selected.industry_ids.filter((id) => !selected.includes(id));
                if (removedIndustries.length > 0) {
                    // Remove subindustries that belong to removed industries
                    const validSubIndustries = prev.selected.sub_industry_ids.filter((subId) => {
                        const subIndustry = prev.options.sub_industries.find((si) => si.id === subId);
                        return subIndustry && selected.includes(subIndustry.section_id);
                    });
                    newSelected.sub_industry_ids = validSubIndustries;

                    // Remove economic activities that belong to removed subindustries
                    const validEconomicActivities = prev.selected.economic_activity_ids.filter((eaId) => {
                        const economicActivity = prev.options.economic_activities.find((ea) => ea.id === eaId);
                        return economicActivity && validSubIndustries.includes(economicActivity.division_id);
                    });
                    newSelected.economic_activity_ids = validEconomicActivities;
                }
            }
            // When changing subindustries
            else if (type === "sub_industry") {
                const removedSubIndustries = prev.selected.sub_industry_ids.filter((id) => !selected.includes(id));
                if (removedSubIndustries.length > 0) {
                    // Remove economic activities that belong to removed subindustries
                    const validEconomicActivities = prev.selected.economic_activity_ids.filter((eaId) => {
                        const economicActivity = prev.options.economic_activities.find((ea) => ea.id === eaId);
                        return economicActivity && selected.includes(economicActivity.division_id);
                    });
                    newSelected.economic_activity_ids = validEconomicActivities;
                }
            }

            // Update the directly changed selection
            newSelected[key] = selected;

            return {
                ...prev,
                selected: newSelected,
            };
        });
    }, []);

    return [activities, handleSelect];
}

export function useSites() {
    const { state, dispatch } = useContext(AppContext);
    const team = useTeam();
    const project = useProject();
    const [page, setPage] = React.useState<number>(1);
    const [totalPages, setTotalPages] = React.useState<number>(1);
    const [order, setOrder] = React.useState<IOrderParam>({
        by: "name",
        direction: "asc",
    });

    const sites = state.sites;

    React.useEffect(() => {
        if (project && page < totalPages) {
            fetchSites(page + 1, order);
        }
    }, [page, totalPages]);

    const fetchSites = React.useCallback(
        async (pageToFetch: number, order: IOrderParam = { by: "site_name", direction: "asc" }) => {
            // const sitesUrl = `projects/${project.slug}/reports/${project.latest_report_id}/site/page/${pageToFetch}`;
            // const params = {
            //     'order_by': order.by,
            //     'order_direction': order.direction,
            // };
            let NewDbUrl = `${process.env.KUYUA_APP_BACKEND_URL}/sites?page=1&priority_location=true&sortBy=priority_score&sortOrder=desc&company_id=${team.id}`;
            let NewDbUrlQueries = [];
            NewDbUrlQueries.push("sortBy=" + order.by);
            NewDbUrlQueries.push("sortOrder=" + order.direction);
            try {
                NewDbUrl = NewDbUrl + "&" + NewDbUrlQueries.join("&");
                console.log(NewDbUrl);
                const response = await fetch(NewDbUrl, header);
                const data = await response.json();
                setPage(pageToFetch);
                setTotalPages(data.totalPages);

                // will be the case for first fetch or after reset
                let newSites: INewDBSite[];

                if (pageToFetch === 1) {
                    newSites = data.sites;
                } else {
                    newSites = [...sites, ...data.sites];
                }

                dispatch({ type: SitesChanged, sites: newSites });
                console.log("mapped sites from hook ===>>> ", data.sites);
            } catch (error) {
                console.error("Error fetching sites from kuyuaappdb:", error);
            }
            // apiGet<{pages: number, current_page: number, items: INewSite[]}>(team.slug, sitesUrl, params).then(reply => {
            //     if (reply.ok) {
            //         setPage(reply.data.current_page);
            //         setTotalPages(reply.data.pages);

            //         // will be the case for first fetch or after reset
            //         let newSites: INewSite[];

            //         if (pageToFetch === 1) {
            //             newSites = reply.data.items;
            //         } else {
            //             newSites = [...sites, ...reply.data.items];
            //         }

            //         dispatch({ type: SitesChanged, sites: newSites });
            //     }
            // });
        },
        [sites, order],
    );

    React.useEffect(() => {
        if (sites.length === 0 && project) {
            fetchSites(page);
        }
    }, [project]);

    const updateSites = React.useCallback(
        (orderParam?: IOrderParam) => {
            dispatch({ type: SitesChanged, sites: [] });
            setPage(1);
            setTotalPages(1);
            setOrder(orderParam);
            project && fetchSites(1, orderParam);
        },
        [project],
    );

    return { sites, fetchSites: updateSites };
}
