import * as React from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { ErrorBoundary } from "@sentry/react";
import { packages, useCompanyPackages, useProject, useTeam } from "@/context";
import { apiGet, getBBoxFromSourceData, t, getCountry, getHomeUrl } from "@/utils";
import { ErrorPanel, LoadingAnimation, TabBar } from "@/lib";
import { SiteOverview } from "./overview/SiteOverview";
import SiteInput from "./uploadSite/SiteInput";
import BSA from "./bsa/BSA";
import SocialIndicators from "./socialIndicators/SocialIndicators";
import IndigenousPeople from "./indigenous/IndigenousPeople";
import "./index.scss";
import { colors, StyleSheet } from "@/utils/style";
import { SpeciesPage } from "@/project/site/species/SpeciesPage";
import SingleAction from "@/project/site/actions/SingleAction";
import Actions from "./actions";
import CryptoJS from "crypto-js";
import { AppContext } from "@/context";
import { calculateZoomLevel } from "@/utils/calculateZoomLevel";
import { SubscriptionTooltip } from "@/components/SubscriptionTooltip";
function getTabs() {
    return [
        { id: "overview", label: t("ui.overview") },
        { id: "site-input", label: t("Materiality Ratings") },
        // { id: "species", label: t("ui.species") },
        // { id: "bsa", label: t("ui.bsa") },
        { id: "indigenous", label: t("Indigenous People") },
        { id: "social-indicators", label: t("EU DR") },
        { id: "actions", label: t("ui.action_plan") },
        //{ id: 'key-area', label: t('ui.key_area') },
    ] as { id: string; label: string; postfixIcon?: string }[];
}

const SubscriptionDefaultElement = () => {
    return (
        <div
            style={{
                padding: "20px",
                textAlign: "center",
            }}
        >
            <SubscriptionTooltip show={true} alwaysShow={true}>
                <p>This feature is not available</p>
            </SubscriptionTooltip>
        </div>
    );
};

export const SiteDetail: React.FC = () => {
    const navigate = useNavigate();
    const { siteId } = useParams();
    const project = useProject();
    const { state } = React.useContext(AppContext);
    const team = useTeam();
    let tabs = getTabs();
    const report = project.reports[project.latest_report_id] as IAssessmentReport;
    const [site, setSite] = React.useState<INewSiteDetailed>(undefined);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [tabId, setTabId] = React.useState<number | string>("overview");
    const [bbox, setBBox] = React.useState<number[]>(undefined);
    const [speciesAlldata, setSpecies] = React.useState({});
    const [siteKpis, setSiteKPIs] = React.useState({});
    const companyPackages = useCompanyPackages();
    const [viewedSpeciesLayer, setViewedSpeciesLayer] = React.useState<string>(null);
    const [refreshKey, setRefreshKey] = React.useState(0);
    const encryptedToken = CryptoJS.AES.encrypt(process.env.API_KEY, process.env.SECRET_KEY).toString();
    const header = {
        headers: {
            Authorization: `Bearer ${encryptedToken}`,
        },
    };
    const isEUDREnabled = companyPackages?.length > 0 ? companyPackages.some((pkg) => pkg.feature_name === packages.EUDR) : false;
    const isIndegineousEnabled = companyPackages?.length > 0 ? companyPackages.some((pkg) => pkg.feature_name === packages.INDIGENOUS_PEOPLE) : false;
    const isActionPlanEnabled = companyPackages?.length > 0 ? companyPackages.some((pkg) => pkg.feature_name === packages.ACTION_PLAN) : false;
    const isMaterialityRatingsEnabled = companyPackages?.length > 0 ? companyPackages.some((pkg) => pkg.feature_name === packages.MATERIALITY_RATINGS) : false;

    const siteRadius = parseInt(localStorage.getItem("siteRadius"));
    async function getSite(id: number) {
        const url = `projects/${project.slug}/reports/${report.id}/site/${id}/detailed-view`;
        const NewDbUrl = `${process.env.KUYUA_APP_BACKEND_URL}/site?id=${id}`;
        const response = await fetch(NewDbUrl, header);
        const data = await response.json();
        await getCountry(data.latlng.lat, data.latlng.lng, state).then(async (res) => {
            data.country_code = res.countryCode.toUpperCase();
            setSite(data);
            console.log("site from siteDetails:", data);
            //const sp = await fetchSpecies(data.latlng.lat, data.latlng.lng, data.radius, res.countryCode.toUpperCase());
            const kpisResponse = await fetchSiteKPIS(data);
            const { speciesData } = kpisResponse;
            console.log("KPIS Data: ", kpisResponse);
            setSiteKPIs(kpisResponse);
            setSpecies(speciesData);
            setLoading(false);
        });
    }
    async function fetchSiteKPIS(site) {
        try {
            const KPIsURL = `${process.env.KUYUA_APP_BACKEND_URL}/site/kpis?site_id=${site.site_id}&kpis=msa,msa-delta,bsa,bsa-distance,habitats,npp,species,change-y-average,deforestation,water-risk,materiality`;
            const response = await fetch(KPIsURL, header);
            const data = response.json();
            return data;
        } catch (error) {
            console.error("Error at fetch kpis.");
        }
    }
    // Callback to update the site after editing
    const refreshSiteData = () => {
        console.log("Site is being refreshed");

        setTimeout(() => {
            getSite(parseInt(siteId));
            setRefreshKey((prev) => prev + 1);
        }, 1000);
    };

    const onLayerLoad = (id: string, layer: IKeyAreaLayer) => {
        if (id === viewedSpeciesLayer) {
            setBBox(getBBoxFromSourceData(layer.geometry, 0.05));
        }
    };

    const onTabClick = (id: string) => {
        if (id !== tabId) {
            setTabId(id); // Update the state only if the tab is different
            if (id !== "species") {
                setViewedSpeciesLayer(null); // Reset species layer only if necessary
            }
            navigate(`./${id}${document.location.search}`);
        } else {
            navigate(`${getHomeUrl(team)}/site/${site.site_id}/${tabId}`);
        }
    };

    const onSpeciesClick = (species: ISpecies, what: "info" | "map") => {
        if (what === "map") {
            setViewedSpeciesLayer(`species/${site.site_id}/${species.id}`);
        }
    };

    React.useEffect(() => {
        if (!site || parseInt(site.site_id) !== parseInt(siteId)) {
            setLoading(true);
            getSite(parseInt(siteId));
        }

        if (site) {
            document.title = site?.site_name ? `${site.site_name} — ${project.name} — kuyua` : `${project.name} — kuyua`;
        } else {
            if (loading) {
                document.title = t("ui.loading");
            } else {
                document.title = t("ui.site_not_found");
            }
        }
    }, [site, loading, siteId]);

    React.useEffect(() => {
        const path = document.location.pathname;
        const matchedTabId = tabs.find((tab) => path.includes(tab.id))?.id || "overview"; // Match based on available tabs
        setTabId(matchedTabId);
    }, [document.location.pathname]);
    const handleSiteType = (site_type_id) => {
        switch (site_type_id) {
            case "1":
                return "own";
            case "2":
                return "upstream";
            case "3":
                return "downstream";
            default:
                break;
        }
    };
    if (!loading && !site) {
        return (
            <ErrorPanel
                style={{ margin: "30vh auto 0 auto" }}
                text={`The site you've navigated to does not exist or has been deleted`}
                title="Site Not Found"
            />
        );
    }

    if (loading) {
        return <LoadingAnimation />;
    }

    const layers: string[] = [];

    if (viewedSpeciesLayer) {
        layers.push(viewedSpeciesLayer);
    }

    const props = { project, report, site, siteKpis };
    const mapZoom = calculateZoomLevel(siteRadius);
    return (
        <div style={styles.wrapper}>
            <TabBar activeId={tabId} onClick={onTabClick} tabs={tabs} />

            <div className="s-overview">
                {tabId !== "overview" && (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: ".5rem",
                        }}
                    >
                        <h1
                            style={{
                                fontSize: 40,
                                color: colors.brightBlue,
                                margin: "unset",
                                marginRight: 20,
                            }}
                        >
                            {site.site_name ?? site.site_id}
                        </h1>
                        <span style={{ fontSize: 20, marginRight: 10 }}>Site Type: </span>
                        <span
                            style={{
                                fontSize: 20,
                                color: colors.brightBlue,
                                textTransform: "capitalize",
                            }}
                        >
                            {handleSiteType(String(site.site_type_id))}
                        </span>
                    </div>
                )}
                <ErrorBoundary fallback={<p>Failed to load tab content</p>}>
                    <Routes>
                        <Route
                            path="/overview"
                            element={
                                speciesAlldata?.species ? (
                                    <SiteOverview
                                        site={site}
                                        bbox={bbox}
                                        layers={layers}
                                        speciesData={speciesAlldata}
                                        onLayerLoad={onLayerLoad}
                                        refreshKey={refreshKey}
                                    />
                                ) : (
                                    <span className="spinner"></span>
                                )
                            }
                        />

                        {isMaterialityRatingsEnabled ? (
                            <Route path="/site-input" element={<SiteInput site={site} refreshSiteData={refreshSiteData} />} />
                        ) : (
                            <Route
                                path="/site-input"
                                element={
                                    <SubscriptionDefaultElement />
                                }
                            />
                        )}
                        <Route
                            path="/species"
                            element={
                                speciesAlldata?.species ? (
                                    <SpeciesPage
                                        site={site}
                                        bbox={bbox}
                                        layers={layers}
                                        speciesData={speciesAlldata}
                                        onLayerLoad={onLayerLoad}
                                        onClick={onSpeciesClick}
                                        mapZoom={mapZoom}
                                        setTabId={setTabId}
                                    />
                                ) : (
                                    <span className="spinner"></span>
                                )
                            }
                        />
                        {isActionPlanEnabled ? (
                            <>
                                <Route path="/actions/" element={<Actions {...props} />} />
                                <Route path="/actions/:clusterName" element={<SingleAction site={site} />} />
                            </>

                        ) : (
                            <Route
                                path="/actions"
                                element={
                                    <SubscriptionDefaultElement />
                                }
                            />
                        )}
                        <Route path="/bsa" element={<BSA site={site} setTabId={setTabId} />} />
                        {isEUDREnabled ? (
                            <Route
                                path="/social-indicators"
                                element={<SocialIndicators latlng={site.latlng} bbox={bbox} mapZoom={mapZoom} site={site} />}
                            />
                        ) : (
                            <Route
                                path="/social-indicators"
                                element={
                                    <SubscriptionDefaultElement />
                                }
                            />
                        )}

                        {isIndegineousEnabled ? (
                            <Route path="/indigenous" element={<IndigenousPeople setTabId={setTabId} site={site} />} />
                        ) : (
                            <Route
                                path="/indigenous"
                                element={
                                    <SubscriptionDefaultElement />
                                }
                            />
                        )}
                    </Routes>
                </ErrorBoundary>
            </div>
        </div>
    );
};

const styles = StyleSheet.create({
    wrapper: {
        padding: "0 100px",
        top: 100,
        position: "absolute",
        width: "96%",
    },
});
