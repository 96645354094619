import * as React from "react";

export default function LoadingAnimation({ text }: { text?: string }): JSX.Element {
    return (
        <div
            className="loading-wrapper"
            {...(text && {
                style: { display: "flex", flexDirection: "column", alignItems: "center" },
            })}
        >
            <div className="loading-spinner" />
            {text && <div>{text}</div>}
        </div>
    );
}
