import * as React from "react";
import { mdiArrowRightThin, mdiWindowClose } from "@mdi/js";
import { AppContext, useTeam, useTheme } from "@/context";
import { Icon, ErrorPanel, Dropdown, ActionLink, LoadingAnimation } from "@/lib";
import { BaseModalDialog } from "@/lib/modals";
import { apiGet, t } from "@/utils";
import { getRatingTextFromNumber } from "@/utils/getRatingText";
import "./index.scss";
import { Link, NavLink } from "react-router-dom";
import CryptoJS from "crypto-js";

const MitigationOptions = [
    { id: "avoid", label: "Avoid" },
    { id: "minimise", label: "Minimize" },
    { id: "restore", label: "Restore" },
    // { id: 'offset', label: 'Offset' },
];

const capitalize = (string: string) => {
    if (!string) {
        return string;
    }
    return string[0].toUpperCase() + string.substring(1);
};

const createFilterLabel = (activeFilters: string[], allFilters: { id: string; label: string }[]) => {
    if (activeFilters.length === 0) {
        return t("ui.all");
    }
    return allFilters
        .filter((o) => !!activeFilters.find((f) => f === o.id))
        .map((o) => o.label)
        .join(", ");
};

interface ISiteActionsProps {
    project: IProject;
    report: IAssessmentReport;
    site: INewSiteDetailed;
    siteKpis: {};
}

const toggleFilter = (id: string, filters: string[], setter: React.Dispatch<React.SetStateAction<string[]>>) => {
    if (filters.find((item) => item === id)) {
        setter(filters.filter((filter) => filter !== id));
    } else {
        setter(filters.concat(id));
    }
};

export default function SiteActionsTab(props: ISiteActionsProps): JSX.Element {
    const { dispatch, state } = React.useContext(AppContext);
    const team = useTeam();
    const theme = useTheme();
    const [ipbesFilters, setIpbesFilters] = React.useState<string[]>([]);
    const [mitigationFilters, setMitigationFilters] = React.useState<string[]>([]);
    const [showPopup, setShowPopup] = React.useState<"ipbes" | "mitigation" | null>(undefined);
    const [ipbesOptions, setIpbesOptions] = React.useState<{ id: string; label: string }[]>(undefined);
    const [stats, setStats] = React.useState<ISiteStatistics[]>(undefined);
    const [measureChanged, setMeasureChanged] = React.useState<boolean>(false);
    const [actionPlans, setActionPlans] = React.useState([]);
    const [filteredActionPlans, setFilteredActionPlans] = React.useState({});
    const [subIndustryName, setSubIndustryName] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(true);
    const encryptedToken = CryptoJS.AES.encrypt(process.env.API_KEY, process.env.SECRET_KEY).toString();
    const header = {
        headers: {
            Authorization: `Bearer ${encryptedToken}`,
        },
    };
    const tmpEcoActIds = props.site.economic_activities.map((item: any) => item.economic_activity_id);
    const economicActivitiesIds = Array.from(new Set(tmpEcoActIds)).toString();
    const subIndustryIds = props.site.economic_activities.map((item: any) => item.subindustry_id);
    const uniqueStringSubIndustryIds = Array.from(new Set(subIndustryIds)).toString();

    async function getActionPlans() {
        setSubIndustryName(props.site.economic_activities[0].subindustry_name);
        const url = `${process.env.GEO_MIND_URL}/action-plan/recommendations/`;
        const encryptedToken = CryptoJS.AES.encrypt(process.env.API_KEY, process.env.SECRET_KEY).toString();
        const siteKpisData = props.siteKpis;
        const recommendationsAPIBody = {
            site_conditions: await formatSiteConditions(siteKpisData),
            impacts_dependencies: transformToImpactDependencyString(siteKpisData),
            top_k: 50,
            sub_industries_ids: uniqueStringSubIndustryIds,
        };
        console.log("Action plan post body: ", recommendationsAPIBody);
        await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${encryptedToken}`,
            },
            body: JSON.stringify(recommendationsAPIBody),
        })
            .then(async (response) => {
                const allData = await response.json();
                const groupedByClusterNameAP = allData?.data.reduce((acc, item) => {
                    const clusterName = item.action.cluster_name;

                    if (!acc[clusterName]) {
                        acc[clusterName] = [];
                    }
                    try {
                        const tmpIpbes = item.action.ipbes ? JSON.parse(item.action.ipbes) : [];
                        item.action.ipbes = tmpIpbes.length > 0 ? tmpIpbes[0] : null;
                    } catch (error) {
                        console.error("Error parsing ipbes field for item: ", item, error);
                        item.action.ipbes = null;
                    }
                    acc[clusterName].push(item);
                    return acc;
                }, {});
                console.log("action plan: ", groupedByClusterNameAP);
                const uniquePlans = await ensureUniqueRecommendations(groupedByClusterNameAP);
                setActionPlans(uniquePlans);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error("Error:", error);
                setIsLoading(false);
            });
    }
    async function ensureUniqueRecommendations(data) {
        const result = {};

        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const seenIds = new Set(); // Track unique recommendation_ids
                result[key] = data[key].filter((item) => {
                    const id = item.action.recommendation_id;
                    if (!seenIds.has(id)) {
                        seenIds.add(id);
                        return true;
                    }
                    return false;
                });
            }
        }

        return result;
    }
    const fetchWaterRiskImpact = async () => {
        const url = `${process.env.KUYUA_APP_BACKEND_URL}/impacts/max?site_id=${props.site.site_id}&economic_activities_ids=${economicActivitiesIds}
        &impact_names=water_use,water_pollutants`;
        try {
            const response = await fetch(url, header);
            const data = await response.json();
            if (data.max_impact !== "N/A") {
                return data.max_impact;
            } else {
                return data.potential_max_impact;
            }
        } catch (error) {
            console.error("Error fetching water risk impact:", error);
        }
    };
    const fetchDeforestationRiskImpact = async () => {
        const url = `${process.env.KUYUA_APP_BACKEND_URL}/impacts/max?site_id=${props.site.site_id}&economic_activities_ids=${economicActivitiesIds}&impact_names=terrestrial_ecosystem_use,disturbances`;
        try {
            const response = await fetch(url, header);
            const data = await response.json();
            if (data.max_impact !== "N/A") {
                return data.max_impact;
            } else {
                return data.potential_max_impact;
            }
        } catch (error) {
            console.error("Error fetching deforestation risk impact:", error);
        }
    };
    const fetchWaterRiskDependency = async () => {
        const url = `${process.env.KUYUA_APP_BACKEND_URL}/dependencies/max?site_id=${props.site.site_id}&economic_activities_ids=${economicActivitiesIds}&dependency_names=surface_water,ground_water,water_flow_maintenance,water_quality`;
        try {
            const response = await fetch(url, header);
            const data = await response.json();
            if (data.max_dependency !== "N/A") {
                return data.max_dependency;
            } else {
                return data.potential_max_dependency;
            }
        } catch (error) {
            console.error("Error fetching water risk Dependency:", error);
        }
    };
    const fetchDeforestationRiskDependency = async () => {
        const url = `${process.env.KUYUA_APP_BACKEND_URL}/dependencies/max?site_id=${props.site.site_id}&economic_activities_ids=${economicActivitiesIds}&dependency_names=climate_regulation,flood_and_storm_protection,filtration,mass_stabilisation_and_erosion_control,mediation_of_sensory_impacts`;
        try {
            const response = await fetch(url, header);
            const data = await response.json();
            if (data.max_dependency !== "N/A") {
                return data.max_dependency;
            } else {
                return data.potential_max_dependency;
            }
        } catch (error) {
            console.error("Error fetching Deforestation risk Dependency:", error);
        }
    };
    async function formatSiteConditions(data) {
        const [waterRiskImpact, deforestationRiskImpact, waterRiskDependency, deforestationRiskDependency] = await Promise.all([
            fetchWaterRiskImpact(),
            fetchDeforestationRiskImpact(),
            fetchWaterRiskDependency(),
            fetchDeforestationRiskDependency(),
        ]);
        const habitatDict = data.habitatMigrationData.habitatDict;
        const habitatStr = habitatDict.map((item) => `${item.name} ${item.percentage}%`).join(", ");
        const tmpThreatenedAndKeystoneSpecies = data.speciesData?.key_stone_threatened_species_count;
        const tmpBss =
            data.speciesData?.key_stone_species_count === 0
                ? 0
                : (tmpThreatenedAndKeystoneSpecies / data.speciesData?.key_stone_species_count) * 100;
        const siteConditions =
            `Biodiversity Intactness ${data.msa?.toFixed(0)}%, ` +
            `Projected Change in Biodiversity Intactness ${data.msaDelta}%, ` +
            `Species Richness ${data.speciesData.total_unique_keystone_species}, ` +
            `Biodiversity-Sensitive Areas (BSA) ${data.BSACount}, ` +
            `Vegetation Productivity (gC/m²/year, 2023) ${data.netPrimary || "N/A"}, ` +
            `Trend in Vegetation Productivity ${data.changeYAverage}, ` +
            `Threatened Species ${data.speciesData.threatened_species_count}, ` +
            `Keystone Species Richness ${data.speciesData.key_stone_species_count}, ` +
            `Keystone Species @ Risk ${tmpBss.toFixed(1)}%, ` +
            `Water Risk ${data.waterRiskCategory}, ` +
            `Water Impact Level ${getRatingTextFromNumber(waterRiskImpact)}, ` +
            `Water Dependency Level ${getRatingTextFromNumber(waterRiskDependency)}, ` +
            `Deforestation ${data.deforestationData?.year}, ` +
            `Deforestation dominant Driver ${data.deforestationData?.deforestation_category}, ` +
            `Deforestation Impact Level ${getRatingTextFromNumber(deforestationRiskImpact)}, ` +
            `Deforestion Dependency Level ${getRatingTextFromNumber(deforestationRiskDependency)}, ` +
            `${habitatStr}`;
        return siteConditions;
    }
    // Map rating abbreviations to full forms
    const ratingMap = {
        VH: "Very High",
        H: "High",
        M: "Medium",
        L: "Low",
    };
    const transformToImpactDependencyString = (data) => {
        const impacts = data?.impacts?.potentialImpacts?.restructuredItems;
        const dependencies = data?.dependencies?.potentialDependencies?.restructuredItems;
        const impactsArray = impacts.flatMap((impact) =>
            impact.values.map((value) => `${ratingMap[impact.rating]} impact ${formatValue(value)}`),
        );

        const dependenciesArray = dependencies.flatMap((dependency) =>
            dependency.values.map((value) => `${ratingMap[dependency.rating]} dependency ${formatValue(value)}`),
        );

        // Combine both impacts and dependencies into one string
        return [...impactsArray, ...dependenciesArray].join(", ");
    };

    // Function to format values into a more human-readable form
    const formatValue = (value) => value.replace(/_/g, " "); // Replace underscores with spaces

    React.useEffect(() => {
        getActionPlans();
        const encryptedToken = CryptoJS.AES.encrypt(process.env.API_KEY, process.env.SECRET_KEY).toString();
    }, []);

    React.useEffect(() => {
        setFilteredActionPlans(actionPlans);
    }, [actionPlans]);

    React.useEffect(() => {
        if (mitigationFilters.length > 0) {
            setIpbesFilters([]);
        }
    }, [mitigationFilters]);

    React.useEffect(() => {
        if (ipbesFilters.length > 0) {
            setMitigationFilters([]);
        }
    }, [ipbesFilters]);

    const updateFilteredActionPlans = React.useCallback(() => {
        if (mitigationFilters.length > 0) {
            // const filtered = actionPlans.filter(plan => mitigationFilters.includes(plan.mitigation.description.toLowerCase()));
            const filtered = Object.keys(actionPlans).reduce((acc, key) => {
                const filteredArray = actionPlans[key].filter((item) =>
                    mitigationFilters.includes(item.action.mitigation_description.toLowerCase()),
                );
                if (filteredArray.length > 0) {
                    acc[key] = filteredArray;
                }
                return acc;
            }, {});
            setFilteredActionPlans(filtered);
        } else if (ipbesFilters.length > 0) {
            //const filtered = actionPlans.filter(plan => plan.cluster.ipbes.some((ipbes: any) => ipbesFilters.includes(ipbes.code)));
            const filtered = Object.keys(actionPlans).reduce((acc, key) => {
                const filteredArray = actionPlans[key].filter((item) => ipbesFilters.includes(item.action?.ipbes?.code));
                if (filteredArray.length > 0) {
                    acc[key] = filteredArray;
                }
                return acc;
            }, {});
            setFilteredActionPlans(filtered);
        } else {
            setFilteredActionPlans(actionPlans);
        }
    }, [mitigationFilters, ipbesFilters, actionPlans]);

    React.useEffect(() => {
        updateFilteredActionPlans();
    }, [updateFilteredActionPlans]);

    React.useEffect(() => {
        const uniqueIpbes = Object.values(actionPlans).reduce((acc, cluster) => {
            cluster.forEach(({ action }) => {
                // Accessing the 'ipbes' object
                const ipbesObject = action.ipbes;
                if (ipbesObject !== null) {
                    acc[ipbesObject.code] = ipbesObject.name;
                }
            });
            return acc;
        }, []);
        const result = Object.entries(uniqueIpbes).map(([id, label]) => ({
            id,
            label,
        }));
        if (result.length > 0) {
            console.log("IPBES kpi: ", result);
            setIpbesOptions(result);
        }
    }, [actionPlans]);

    React.useEffect(() => {
        //TODO cancel statistics for now from old URLs
        // if (!stats || measureChanged) {
        //     setMeasureChanged(false);
        //     const url = `projects/${props.project.slug}/reports/${props.report.id}/sites/${props.site.site_id}/statistics`;
        //     apiGet<ISiteStatistics[]>(team.slug, url).then(reply => {
        //         if (reply.ok && reply.data && reply.data.length > 0) {
        //             const data = reply.data.map(data => ({ ...data, date: new Date(data.date) }));
        //             setStats(data);
        //         }
        //     });
        // }
    }, [measureChanged]);

    const ipbesFiltersLabel = React.useMemo(() => createFilterLabel(ipbesFilters, ipbesOptions), [ipbesFilters]);
    const mitigationFiltersLabel = React.useMemo(() => createFilterLabel(mitigationFilters, MitigationOptions), [mitigationFilters]);
    const headingHeight = React.useMemo(() => {
        const cardTitles = Array.from(document.querySelectorAll(".s-action-action-card-title"));
        const maxHeight = Math.max(...cardTitles.map((item) => item.getBoundingClientRect().height));

        return maxHeight;
    }, [actionPlans, state.layoutSize]);
    const getHeadingFontSize = (text: string) => {
        const length = text.length;
        return length < 30 ? "1rem" : theme.smallFontSize;
    };

    return (
        <div>
            <div>
                {ipbesOptions && (
                    <div className="s-actions-filters-container">
                        <div className="s-action-filter">
                            <h2 className="s-action-filter-heading">
                                {t("action_plans.ipbes_filters_heading")}
                                <ActionLink onClick={() => setShowPopup("ipbes")} style={{ fontSize: ".85rem" }}>
                                    {t("action_plans.more_info")}
                                </ActionLink>
                            </h2>

                            <Dropdown
                                label="ALL"
                                items={ipbesOptions}
                                selectedIds={ipbesOptions.filter((o) => ipbesFilters.includes(o.id)).map((o) => o.id)}
                                onItemClick={(id) => toggleFilter(id, ipbesFilters, setIpbesFilters)}
                                selectedIcon={mdiWindowClose}
                            />
                        </div>

                        <div className="s-action-filter">
                            <h2 className="s-action-filter-heading">
                                {t("action_plans.mitigation_filters_heading")}
                                <ActionLink onClick={() => setShowPopup("mitigation")} style={{ fontSize: ".85rem" }}>
                                    {t("action_plans.more_info")}
                                </ActionLink>
                            </h2>
                            <Dropdown
                                label={mitigationFiltersLabel}
                                items={MitigationOptions}
                                selectedIds={mitigationFilters}
                                onItemClick={(id) => toggleFilter(id, mitigationFilters, setMitigationFilters)}
                                selectedIcon={mdiWindowClose}
                            />
                        </div>
                    </div>
                )}
                {isLoading ? (
                    <LoadingAnimation />
                ) : Object.keys(filteredActionPlans).length !== 0 ? (
                    <div className="s-action-action-cards-container">
                        <>
                            {Object.keys(filteredActionPlans).map((key) => (
                                <NavLink
                                    className="s-action-action-card"
                                    key={key}
                                    to={{
                                        pathname: `./${filteredActionPlans[key][0].action.cluster_id}`,
                                    }}
                                    state={{ data: filteredActionPlans[key] }} // Passing data through state
                                >
                                    <div
                                        className={`s-action-action-card-title s-action-action-card-title-${filteredActionPlans[key][0].action?.mitigation_description?.toLowerCase()}`}
                                        style={{ height: headingHeight, fontSize: getHeadingFontSize(key) }}
                                    >
                                        <span className="s-action-action-card-title-text">{key}</span>
                                        <span className="s-label">{filteredActionPlans[key][0].action.mitigation_description}</span>
                                    </div>
                                    <div className="s-action-action-card-footer">
                                        <span>
                                            {t("action_plans.tasks_completed")}{" "}
                                            {filteredActionPlans[key].length + "/" + filteredActionPlans[key].length}
                                        </span>
                                        <Icon color="#7796C9" path={mdiArrowRightThin} size={32} />
                                    </div>
                                </NavLink>
                            ))}
                        </>
                    </div>
                ) : (
                    <ErrorPanel title={`No actions for this Division: ${subIndustryName}`} style={{ marginTop: "3rem" }} />
                )}
            </div>

            {!actionPlans && <ErrorPanel title="No items" style={{ marginTop: "3rem" }} />}

            {showPopup && (
                <BaseModalDialog
                    title={showPopup === "mitigation" ? t("mitigation.title") : t("ipbes.title")}
                    onClose={() => setShowPopup(null)}
                >
                    <div>
                        {showPopup === "mitigation" ? (
                            <div>
                                {t("mitigation.info")}
                                <img src="/-/app/mitigation-hierarchy.png" width="100%" />
                            </div>
                        ) : (
                            t("ipbes.info")
                        )}
                    </div>
                </BaseModalDialog>
            )}
        </div>
    );
}
